module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Origin Café","short_name":"Origin Café","start_url":"/","background_color":"#663399","theme_color":"#663399","icon":"src/images/favicon.png","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8dd3e4844e1d39d58c643b410b6a62df"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
