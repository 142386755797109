import React from "react";
import { Provider } from "react-redux";
import firebase from "firebase/compat/app";
import { enableIndexedDbPersistence } from "firebase/firestore";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import 'firebase/functions' // <- needed if using httpsCallable
import { createStore, combineReducers } from "redux";
import {
    ReactReduxFirebaseProvider,
    firebaseReducer,
} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore"; // <- needed if using firestore
import { firebaseConfig as fbConfig } from "./firebase";

// react-redux-firebase config
const rrfConfig = {
    userProfile: "users",
    useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

// Initialize firebase instance
firebase.initializeApp(fbConfig);

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore
/*if (typeof window !== 'undefined') {
    firebase.firestore().enablePersistence();
}*/
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
    firebase: firebaseReducer,
    firestore: firestoreReducer, // <- needed if using firestore
});

// Create store with reducers and initial state
const initialState = {};
const store = createStore(rootReducer, initialState);

const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance, // <- needed if using firestore
};

// Setup react-redux so that connect HOC can be used
const Wrapper = ({ element }) => (
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            {element}
        </ReactReduxFirebaseProvider>
    </Provider>
);

export default Wrapper;